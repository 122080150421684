export default [
    {
        titulo: 'Como criar uma conta?',
        resposta: `<ol>
            <li>Acesse <a target="_blank" href="https://classificados.bikeregistrada.com.br">https://classificados.bikeregistrada.com.br</a></li>
            <li>Clique em "Entrar"</li>
            <li>Clique em "Criar uma nova conta', e preencha os campos da tela seguinte para se cadastrar com email e senha.</li>
            <li>Para finalizar, clique em "Criar conta!"</li>
            </ol>`
    },
    {
        titulo: 'Quero vender minha bike!',
        resposta: `<ol>
        <li>Acesse <a target="_blank" href="https://classificados.bikeregistrada.com.br">https://classificados.bikeregistrada.com.br</a></li>
        <li>Clique em "Quero vender" no canto superior direito.</li>
        <li>Faça login em sua conta ou crie uma nova conta.</li>
        <li>Clique em "Novo anúncio', escolha qual das suas bicicletas deseja anunciar ou "Anunciar uma outra bike"</li>
        <li>Cadastre as informações gerais, ficha técnica, informações complementares da bike e o preço de venda.</li>
        <li>Adicione as fotos. <i>Dica: Prefira colocar fotos de fundo branco criadas com algum editor de imagem. Evite adicionar bordas, logotipos ou marcas d'agua.</i></li>
        <li>Confirme os dados para contato e salve o anúncio.</li>
        </ol>`
    },
    {
        titulo: 'Como excluir minha conta?',
        resposta: 'Basta enviar um email para contato@bikeregistrada.com.br e solicitar a exclusão.'
    },
    {
        titulo: 'Como alterar meus dados pessoais e email?',
        resposta: 'Para alterar os dados pessoais, faça login na plataforma Bike Registrada e acesse a sessão "Meus Dados".'
    },
    {
        titulo: 'Como alterar/recuperar minha senha?',
        resposta: `<p>Basta clicar na opção <a target="_blank" href="/recuperar-senha">Esqueci minha senha</a>. Você irá receber um e-mail com as instruções para recuperar/alterar sua senha de cadastro.</p>
        <p>
        <strong>Importante!</strong>
        <ul>
            <li>Caso não tenha recebido o e-mail de recuperação de senha, verifique sua caixa de lixo eletrônico e spam. Tente adicionar o email noreplay@bikeregistrada.com.br como remetente salvo para garantir o recebimento dos nossos e-mails.</li>
            <li>Caso tenha encontrado outros problemas para recuperar sua senha ou acessar sua conta, verifique se dados informados estão corretos e nos envie junto com mais detalhes do seu problema(mensagem de erro, prints) através do email contato@bikeregistrada.com.br ou +55 (61) 99688-4521 Whatsapp.</li>
        </ul>
        </p>`
    },
    {
        titulo: 'Dicas para criar uma senha de acesso',
        resposta: `Você sabia que é importante trocar as suas senhas de acesso periodicamente?
        <br>Confira algumas dicas para criar uma boa senha e ajudar na preservação das suas informações pessoais:
        <ul>
            <li>Junto e misturado - Crie senhas com letras maiúsculas e minúsculas, símbolos especiais (* # @ ! ?) e números;</li>
            <li>Senhas diferentes, contas fortes - Evite ao máximo usar senhas iguais em cadastros. Tente criar senhas diferentes usando a mesma lógica, para que fique mais fácil de lembrar depois. Por exemplo: Ana.gost@.gato, Ana.gost@.futebol, Ana.gost@.livros;</li>
            <li>Nada de data de nascimento - Evite usar datas comemorativas como referência. Evite datas de nascimento, aniversário e casamento, entre outras;</li>
            <li>123456 não é uma boa ideia - Não utilize combinações numéricas em sequência (como 123456 ou 98765). Podem parecer muito fáceis de memorizar, mas também são muito fáceis de serem descobertas;</li>
            <li>Nomes? Pense bem - Evite colocar seu nome ou de pessoas queridas na sua senha, principalmente se forem nomes curtos como Ana, Maria ou José;</li>
            <li>Cuidado com computadores públicos - Não salve senhas em computadores ou aparelhos públicos. Este tipo de informação deve ser apenas para o seu próprio uso;</li>
            <li>Diga não às senhas curtas -  Senhas muito longas podem ser mais difíceis de memorizar, mas são as mais difíceis de serem descobertas. Crie senhas com no mínimo 8 caracteres;</li>
            <li>Tenha um bom antivírus atualizado - Você sabia que muitos antivírus avaliam o nível da sua senha? Além desse recurso, eles também podem alertar quando suas contas tiverem atividades suspeitas;</li>
        </ul>
        `
    },
    {
        titulo: 'Como falar com o vendedor/comprador?',
        resposta: `Acesse a opção <a href="dashboard/chat" target="_blank">"Minhas negociações"</a> no menu superior. Lá você terá um chat para falar com o vendedor/comprador.` 
    },
    {
        titulo: 'Dicas de segurança para compradores',
        resposta: `<ul>
            <li>Não deposite antecipadamente: Antes de tudo, sempre evite depositar valores antes de verificar a existência do conteúdo anunciado.</li>
            <li>Atenção a ofertas milagrosas: Antes de iniciar uma negociação, pesquise a média de preço daquilo que deseja. Fique atento aos anúncios com preços muito baixos.</li>
            <li>Denuncie em caso de suspeita: Havendo suspeita, você pode fazer uma denúncia em algum dos nossos canais de comunicação.</li>
            <li>Saiba com quem está negociando: Antes de encontrar a outra pessoa, pergunte o nome completo, lugar onde mora ou trabalha e faça pesquisas na Internet.</li>
            <li>Locais públicos e movimentados: Prefira fechar negócio em um lugar público que ofereça um ambiente seguro, e avise outras pessoas. Se possível, vá acompanhado.</li>
            
        </ul>`
    },
    {
        titulo: 'Dicas de segurança para vendedores',
        resposta: `<ul>
            <li>Saiba com quem está negociando: Antes de encontrar a outra pessoa, pergunte o nome completo, lugar onde mora ou trabalha e faça pesquisas na Internet.</li>
            <li>Atenção aos falsos comprovantes: Cuidado com e-mails com falsos comprovantes de pagamento de sites terceiros.</li>
            <li>Atenção às formas de pagamento: Em casos de transferência bancária e depósito em caixa eletrônico, aguarde o tempo necessário para o valor ser verificado em sua conta.</li>
            <li>Locais públicos e movimentados: Prefira fechar negócio em um lugar público, como shoppings e metros, que ofereça um ambiente seguro. Se possível, vá acompanhado.</li>
            
        </ul>`
    },
    {
        titulo: 'Dicas de como fazer um bom anúncio',
        resposta: `
        <p>Nós conectamos pessoas para que elas possam comprar e vender de forma fácil e rápida, mas não participamos ou interferimos de qualquer forma nas negociações.</p>
        <ul>
            <li>Imagens: Elas são essenciais em um anúncio, pois demonstram o estado do produto que você está oferecendo. 
            Anúncios com fotos são vistos até 7 vezes mais, além de atraírem a atenção dos compradores.
            <br><i>Atenção:</i> Não é permitido utilizar imagens que contenham links ou e-mails.</li>
            <li>Título - Procure ser objetivo. O título deve ser a parte do anúncio que descreve o produto/serviço oferecido em poucas palavras. 
            <br><i>Lembre-se:</i> O título não pode conter palavras ou símbolos que não tenham relação com o que está sendo anunciado. Exemplo: “vendo”, “compro”, “oportunidade”, @#$%*, entre outros.</li>
            <li>Contato: Crie a sua conta usando o seu próprio e-mail e telefone. Ter contatos verídicos evita denúncias de outros usuários.</li>
            <li>Preço: O preço é um ponto muito importante no anúncio. Por isso, sempre coloque o valor total no campo “Preço”. Exemplo: Se você está vendendo uma Bike por R$ 3.000,00, coloque este valor no campo e, na descrição, cite as parcelas.</li>
            <li>Campos de seleção: Em algumas categorias você poderá encontrar filtros para detalhar o produto/serviço oferecido. Isso facilita na busca dos anúncios. Preencha-os corretamente para que os usuários encontrem o máximo de informações.</li>
            <li>Descrição: Descreva bem seu produto/serviço, com todas as informações que você ainda não havia detalhado nos campos anteriores. Mostre para os usuários os motivos para comprar o seu produto ou contratar o seu serviço. 
            Lembre-se: Não são permitidos anúncios que contenham palavras de busca que não estão diretamente relacionadas ao produto/serviço anunciado. Links e e-mails também não são permitidos.</li>
        </ul>`
    }
]