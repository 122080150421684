<template>
  <div>
    <MenuSuperior :exibirBotoesAcao="true" :fixed="true"></MenuSuperior>
    <section id="main" class="menu-superior-fix">
      <div class="container text-left py-5">
        <!-- <h2 class="text-left my-4 text-white">Central de relacionamento</h2> -->
        <h2 class="text-left my-4 text-white">Dúvidas frequentes</h2>
      </div>
    </section>

    <div class="container search-container">
      <div class="card">
        <div class="card-body text-left p-3">
          <div class="row">
            <div class>
              <b-icon icon="search" class="ml-3 h3 mb-0"></b-icon>
            </div>
            <div class="col">
              <input
                type="text"
                v-model="termoPesquisa"
                class="border-0 search-input pt-1 w-100"
                placeholder="Buscar respostas"
                @keyup.enter="() => $event.target.blur()"
              />
            </div>
            <div class>
              <b-icon
                v-if="termoPesquisa"
                icon="x"
                class="mr-3 h3 mb-0 pointer"
                @click.prevent="limparTermoPesquisa"
              ></b-icon>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section>
      <div class="container text-left mt-5">
        <h2 class="mb-4 text-success" v-if="termoPesquisa">Resultados</h2>
        <!-- <h2 class="mb-4 text-success" v-else>Dúvidas Gerais</h2> -->

        <div
          v-if="perguntasFiltradas.length == 0"
        >Nenhum resultado encontrado. Tente procurar com termos diferentes.</div>
        <div>
          <b-card
            no-body
            class="mb-3"
            v-for="(pergunta, index) in perguntasFiltradas"
            :key="'pergunta'+index"
          >
            <b-card-header
              header-tag="header"
              class="p-1 titulo-pergunta"
              role="tab"
              v-b-toggle="'pergunta'+index"
            >
              <div class="row no-gutters">
                <div class="col">
                  <div class="p-2">
                    <TextHighlight :queries="termosPesquisa" :text="pergunta.titulo"></TextHighlight>
                  </div>
                </div>
                <div class="pt-2 pr-2">
                  <small class="collapse-flip-icon">
                    <b-icon icon="chevron-down" class="h4 my-0"></b-icon>
                  </small>
                </div>
              </div>
            </b-card-header>
            <b-collapse :id="'pergunta'+index" role="tabpanel" :visible="!!termoPesquisa">
              <b-card-body>
                <TextHighlight :queries="termosPesquisa" :text="pergunta.resposta"></TextHighlight>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
    </section>
    <Rodape class="mt-5" />
  </div>
</template>

<script>
import TextHighlight from "../helpers/TextHighlight";
import MenuSuperior from "../components/MenuSuperior";
import Rodape from "../components/Rodape";

import faqData from "../data/faqData";

export default {
  components: {
    MenuSuperior,
    TextHighlight,
    Rodape
  },
  data() {
    return {
      termoPesquisa: undefined,
      perguntas: faqData
    };
  },
  computed: {
    termosPesquisa() {
      if (!this.termoPesquisa) {
        return [];
      }

      var lstTermos = (this.termoPesquisa.trim() || "")
        .toLowerCase()
        .split(" ")
        .map(termo => termo.trim());
      return lstTermos;
    },
    perguntasFiltradas() {
      if (!this.termoPesquisa) {
        return this.perguntas;
      }

      var lstTermos = this.termosPesquisa;

      var arrFiltradas = [];

      for (let i = 0; i < this.perguntas.length; i++) {
        const pergunta = this.perguntas[i];

        for (let j = 0; j < lstTermos.length; j++) {
          const termo = lstTermos[j];

          if (
            pergunta.titulo.toLowerCase().indexOf(termo) != -1 ||
            pergunta.resposta.toLowerCase().indexOf(termo) != -1
          ) {
            arrFiltradas.push(pergunta);
            break;
          }
        }
      }

      return arrFiltradas;
    }
  },
  methods: {
    limparTermoPesquisa() {
      this.termoPesquisa = undefined;
    },
    onEnter(ev) {
      ev.target.blur();
    }
  }
};
</script>

<style lang="less" scoped>
@import "../styles/variaveis";

#main {
  background-color: @dark-color;
  background-image: url("/img/fundo_ajuda.jpg");
  background-size: cover;
  background-position: center;
  color: #f2f2f2;
}

.card {
  border-radius: @border-radius;
  border-color: @primary-color;
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.11);
}

.search-input:focus {
  outline: 0;
}

.search-container {
  margin-top: -32px;
}

.pointer {
  cursor: pointer;
}
</style>

