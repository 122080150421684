<template>
  <div v-html="highlight()"></div>
</template>

<script>
//TODO: Está buscando dentro dos elementos HTML

export default {
  props: { queries: Array, text: String },
  methods: {
    highlight() {

      if(this.queries.length == 0){
        return this.text;
      }

      var words = this.text || "";
      var expStr = this.queries.join("|");

      var iQuery = new RegExp(expStr, "ig");
      return words.toString().replace(iQuery, function(matchedTxt) {
        return "<span class='texthighlight-3cc66ad7-2e80-4b9d-93b7-cb2c977c6ac5'>" + matchedTxt + "</span>";
      });
    }
  }
};
</script>


<style>

.texthighlight-3cc66ad7-2e80-4b9d-93b7-cb2c977c6ac5 {
  background-color: yellow;
}
</style>